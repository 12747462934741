export const PAGES_OPTIONS = [
  {
    label: "About",
    value: "about",
  },
  {
    label: "Member",
    value: "member",
  },
  {
    label: "Report",
    value: "report",
  },
  {
    label: "Work Section",
    value: "work_section",
  },
  {
    label: "Media",
    value: "media",
  },
  {
    label: "Projects",
    value: "projects",
  },
];

export const PAGES = {
  ABOUT: "about",
  MEMBER: "member",
  REPORT: "report",
  WORK_SECTION: "work_section",
  MEDIA: "media",
  PROJECTS: "projects",
};

export const ELEMENTS = {
  HOME_IMAGES: "homeImages",
  ORG_IMAGES: "orgImages",
  ORGANISATION_DESCRIPTION: "orgDescription",
  ORGANISATION_PARAGRAPHS: "orgParagraphs",
  CHAIRMAN_IMAGE: "chairmanImage",
  CHAIRMAN_NAME: "chairmanName",
  CHAIRMAN_DESCRIPTION: "chairmanDesc",
  VISION: "vision",
  MISSION: "mission",
  ANNUAL_REPORT: "annual_report",
  AUDIT_REPORT: "audit_report",
  REGISTARTION: "registration",
  //Media
  PHOTOS: "photos",
  VIDEOS: "videos",
  NEWS: "news",
  //Member
  EXECUTIVE: "executive",
  TRUSTEE: "trustee",
  THINK_TANK: "think_tank",
  //REPORT
  ANNUAL: "annual",
  AUDIT: "audit",
  REGISTRATION: "registration",
};

export const ELEMENT_OPTIONS = {
  [PAGES.ABOUT]: [
    {
      label: "Home Images",
      value: ELEMENTS.HOME_IMAGES,
    },
    {
      label: "Organization Images",
      value: ELEMENTS.ORG_IMAGES,
    },
    {
      label: "Organization Description",
      value: ELEMENTS.ORGANISATION_DESCRIPTION,
    },
    {
      label: "Organisation Paragraphs",
      value: ELEMENTS.ORGANISATION_PARAGRAPHS,
    },
    {
      label: "Chairman Name",
      value: ELEMENTS.CHAIRMAN_NAME,
    },
    {
      label: "Chairman Images",
      value: ELEMENTS.CHAIRMAN_IMAGE,
    },
    {
      label: "Chairman Description",
      value: ELEMENTS.CHAIRMAN_DESCRIPTION,
    },
    {
      label: "Vision",
      value: ELEMENTS.VISION,
    },
    {
      label: "Mission",
      value: ELEMENTS.MISSION,
    },
  ],
  [PAGES.REPORT]: [
    {
      label: "Annual report",
      value: ELEMENTS.ANNUAL_REPORT,
    },
    {
      label: "Audit Report",
      value: ELEMENTS.AUDIT_REPORT,
    },
    {
      label: "Registration",
      value: ELEMENTS.REGISTARTION,
    },
  ],
  [PAGES.MEDIA]: [
    {
      label: "News",
      value: ELEMENTS.NEWS,
    },
    {
      label: "Photos",
      value: ELEMENTS.PHOTOS,
    },
    {
      label: "Videos",
      value: ELEMENTS.VIDEOS,
    },
  ],
  [PAGES.MEMBER]: [
    {
      label: "Executive",
      value: ELEMENTS.EXECUTIVE,
    },
    {
      label: "Trustee",
      value: ELEMENTS.TRUSTEE,
    },
    {
      label: "Honourable Members - Mentors",
      value: ELEMENTS.THINK_TANK,
    },
  ],
  [PAGES.REPORT]: [
    {
      label: "Annual",
      value: ELEMENTS.ANNUAL,
    },
    {
      label: "Audit",
      value: ELEMENTS.AUDIT,
    },
    {
      label: "Registration",
      value: ELEMENTS.REGISTARTION,
    },
  ],
};
