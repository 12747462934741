// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageModal_modal__ZxbXq {
  max-width: 896px;
  width: 100%;
  max-height: 90vh;
}
.ImageModal_modal__ZxbXq .ImageModal_headingText__R5l\\+3 {
  display: flex;
  align-self: center;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 22px;
  color: #394759;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/User/MediaCenter/components/ImageModal/ImageModal.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AACJ","sourcesContent":[".modal {\n  max-width: 896px;\n  width: 100%;\n  max-height: 90vh;\n\n  .headingText {\n    display: flex;\n    align-self: center;\n    font-weight: 700;\n    font-size: 1.2rem;\n    line-height: 22px;\n    color: #394759;\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `ImageModal_modal__ZxbXq`,
	"headingText": `ImageModal_headingText__R5l+3`
};
export default ___CSS_LOADER_EXPORT___;
