import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import useMedia from "../../../data-access/useMedia";
import ImageModal from "./components/ImageModal/ImageModal";

const Gallery = ({ type }) => {
  const [images, setImages] = useState([]);
  const { media, mediaLoading } = useMedia({ type });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (media) {
      setImages(media);
    }
  }, [media]);
  return (
    <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 flex justify-items-center">
      {images?.map((image, index) => (
        <div class="flex flex-col gap-2 w-full">
          {type === "videos" ? (
            <ReactPlayer
              url={image?.url?.[0]}
              controls
              width={"100%"}
              height={"200px"}
              style={{ marginBottom: "20px" }}
            />
          ) : (
            <img
              key={index}
              src={image?.url?.[0]}
              alt={` ${index}`}
              class="flex gallery-image self-center h-96 w-full"
              onClick={() => setModal(image)}
            />
          )}
          <div class="flex justify-center self-center break-words whitespace-normal w-full font-semibold text-2xl">
            {image?.caption}
          </div>
        </div>
      ))}
      <ImageModal modal={modal} setModal={setModal} />
    </div>
  );
};

export default Gallery;
