import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
// import { OurWorkItems } from "../data";
import "./Dropdown.css";

function Dropdown({ name, data, bold }) {
  const [click, setClick] = useState(true);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setClick(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleClick = () => setClick(!click);
  return (
    <div ref={dropdownRef}>
      <li
        className={`navLink capitalize group transition duration-300 ${
          bold ? "font-bold" : "hover:font-bold"
        } hover:text-green-400 cursor-pointer ml-2 max-w-35`}
        onClick={handleClick}
      >
        <span className="flex items-center ">
          {name} <IoMdArrowDropdown />
          <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-green-600"></span>
        </span>
      </li>

      <ul
        className={
          click ? "dropdown-menu clicked absolute" : "dropdown-menu absolute"
        }
      >
        <div className="mt-3">
          {data.map((item, index) => {
            return (
              <li key={index} onClick={handleClick}>
                <Link className={item.cName} to={item.path}>
                  {item.title}
                </Link>
              </li>
            );
          })}
        </div>
      </ul>
    </div>
  );
}

export default Dropdown;
