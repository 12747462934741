import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import ReadMore from "../Button/ReadMore";
import "./Carousel.css";

function Carousel({ data, path }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    updateWindowWidth();
    const intervalId = setInterval(updateWindowWidth, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const settings = {
    dots: true,
    infinite: data?.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className=" m-auto w-full  py-[1rem] px-[0.8rem] lg:px-[2rem]  bg-white ">
      <div className="mt-10">
        <Slider {...settings}>
          {data &&
            data.map((d) => (
              <div
                key={d.name}
                className="bg-white h-full text-black rounded-xl min-w-100%"
              >
                <div className="h-72 bg-green-300 flex justify-center items-center rounded-t-xl mx-6 p-2">
                  <img
                    src={d.urlImg}
                    alt=""
                    className="h-52 w-52 rounded-full"
                  />
                </div>
                <div className="flex flex-col items-center justify-center gap-4 p-4">
                  <h4 className="text-xl font-semibold">{d.name}</h4>
                  <p
                    className={`flex flex-col text-center overflow-hidden w-96 h-48`}
                    dangerouslySetInnerHTML={{ __html: d.about }}
                  ></p>
                  <Link to={path} className={"flex"}>
                    {" "}
                    <ReadMore data="Read more" />
                  </Link>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;
