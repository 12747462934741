import React from "react";
import Carousel from "../Carousel/memberCorousel";
import useMember from "../../data-access/useMember";

const ThinkTank = () => {
  const { member, memberLoading } = useMember({ type: "think_tank" });
  console.log("Member:", member);
  return (
    <div>
      <Carousel
        data={member?.map((m) => ({
          name: m?.name,
          about: m?.description,
          urlImg: m?.image?.[0],
        }))}
        path={"/thinktank"}
      />
    </div>
  );
};

export default ThinkTank;
