import React from "react";
import styles from "./ImageModal.module.scss";
import Modal from "../../../../../components/Modal/Modal";

const ImageModal = ({ modal, setModal }) => {
  return (
    <Modal
      showCloseButton
      isModal={modal}
      onClose={() => setModal(false)}
      className={styles.modal}
    >
      <div className={styles.headingText}>{modal?.caption}</div>

      <img
        src={modal?.url?.[0]}
        alt={`My image`}
        class="flex gallery-image self-center h-[60vh] w-full"
      />
    </Modal>
  );
};

export default ImageModal;
