import { useEffect, useRef, useState } from "react";
import styles from "./Modal.module.scss";
import ThemedButton from "../Admin/ThemedButton/ThemedButton";
import useOutsideClickHandler from "../../utils/useOutsideClickHandler";

/**
 * This component is used to make a Modal.
 *
 * @component
 * @example
 * const [modal, setModal] = useState(false)
 *
 * const closeModal = () => {
 * 	setModal(false);
 * 	//some other stuff
 * }
 *
 * return(
 * 	<Modal isModal={modal} onClose={closeModal}>
 * 		content
 * 	</Modal>
 * )
 */

const Modal = ({
  children,
  onClose,
  isModal,
  className,
  overlayClassName,
  showCloseButton = false,
  disableOutsideClick,
  leftCloseIcon = false,
  disableCloseHover,
  outsideClickDeps,
}) => {
  // for closing on outside click
  const modalRef = useRef(null);
  const closeRef = useRef();
  useOutsideClickHandler(
    modalRef,
    onClose,
    disableOutsideClick,
    outsideClickDeps
  );

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isModal) setShowModal(true);
  }, [isModal]);

  useEffect(() => {
    if (closeRef && showModal) closeRef.current.focus();
  }, [closeRef, showModal]);

  const handleKeyDown = (e) => {
    if (e.key === "Escape") onClose();
  };
  const onAnimationEnd = () => {
    if (!isModal) setShowModal(false);
  };

  return (
    showModal && (
      <div
        className={`${styles.modalOverlay} ${overlayClassName} ${
          isModal && styles.open
        }`}
        ref={closeRef}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
      >
        <div
          className={`${styles.modalBox} ${
            isModal ? styles.open : styles.close
          } ${className}`}
          onAnimationEnd={onAnimationEnd}
          ref={modalRef}
        >
          {showCloseButton && (
            <ThemedButton
              className={`${styles.closeIcon} ${
                leftCloseIcon ? styles.leftClose : ""
              } ${disableCloseHover ? styles.closeHoverless : ""}`}
              onClick={onClose}
              theme={"ICON"}
            >
              <i class="fa-solid fa-xmark"></i>
            </ThemedButton>
          )}
          {children}
        </div>
      </div>
    )
  );
};

export default Modal;
