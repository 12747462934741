import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectCarousel = ({ projects }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
  };

  return (
    <Slider {...settings}>
      {projects?.map((project, index) => (
        <div className="" key={index}>
          <div className="">
            <img
              className="w-[100%] h-[28rem]"
              src={project?.img}
              alt={project?.title}
            />
            <div className="flex flex-col items-center ">
              <h3 className="text-xl tracking-widest font-extrabold my-2">
                {project?.projectName}
              </h3>
              <p
                className=" font-semibold overflow-hidden h-48 m-6"
                dangerouslySetInnerHTML={{ __html: project?.description }}
              ></p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ProjectCarousel;
